import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-171fc6c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "store-container" }
const _hoisted_2 = { class: "store-head" }
const _hoisted_3 = { class: "store-body" }
const _hoisted_4 = { class: "store-foot" }

import ABOUT from "./model/Z_J_C_F.vue"
import ScreenVue from '@/view/deal/components/Screen.vue';
import PublicHead from '@com/PublicHead.vue'
import HeadInfo from '../components/store.head.info.vue';
import HeadNav from '../components/store.head.nav.vue';
import DealFoot from "../components/store.public.foot.vue";
import COMPONENTS from '../store';
import { useRouter } from 'vue-router';

export default {
  setup(__props) {

const router = useRouter()
// const ABOUT = COMPONENTS.get(router.currentRoute.value.meta.alias).ABOUT




return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HeadInfo),
      _createVNode(HeadNav)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(ABOUT)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(DealFoot)
    ])
  ]))
}
}

}