import ABOUT_MODEL_Z_J_C_F from "./store-about/model/Z_J_C_F.vue"
const MODEL = (ABOUT) =>{return {ABOUT:ABOUT}}
const COMPONENTS = new Map([
  ['Z_J_C_f', MODEL(ABOUT_MODEL_Z_J_C_F)],
  ['Z_J_G_Y',],
  ['Z_J_H_T',],
  ['Z_J_J_H',],
  ['Z_J_W_G',],
  ['Z_J_G_F',],
  ['Z_J_S_D',],
  ['H_Z_J_Y',],
  ['H_Z_H_G',],
  ['Z_J_T_Z',],
  ['Z_J_Q_Y',]
])
export default COMPONENTS


